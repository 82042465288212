<template>

  
  <div class="box">
    <div class="register">
        <footer>注册</footer>
    <el-form ref="form" :model="form" :rules="rules"  label-width="30px" class="from">
        <el-form-item label="">
        <el-input prefix-icon="el-icon-user" placeholder="注册的账号" v-model.number="form.name"></el-input>
        </el-form-item>
        <el-form-item label="">
        <el-input maxlength="5" prefix-icon="el-icon-film" placeholder="验证码" v-model.number="form.verify" class="inputtow"></el-input>
        </el-form-item>
        <el-form-item label="" prop="psw">
        <el-input autocomplete="off" prefix-icon="el-icon-lock" placeholder="至少6位字母和数字" show-password v-model.number="form.psw"></el-input>
        </el-form-item>
        <el-form-item label="" prop="pswok">
        <el-input autocomplete="off" prefix-icon="el-icon-lock" placeholder="至少6位字母和数字" show-password v-model.number="form.pswok"></el-input>
        </el-form-item>
        <el-form-item label=""  prop="email"
    :rules="[
      { required: true, message: '请输入邮箱地址', trigger: 'blur' },
      { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
    ]">
        <el-input validate-event prefix-icon="el-icon-message" placeholder="邮箱地址" v-model="form.email"></el-input>
        </el-form-item>
  </el-form>
  <div class="buttonVerify">
        <el-button type="primary" :disabled='isforbidden' @click="verificationcode">获取验证码</el-button>
    </div>
    <el-button type="primary" :round=false :plain=false class="buttonres" @click="reg('form')">注册</el-button>
    <div class="login">已有账号，
        <router-link to="/login">登录</router-link>
        <span style="margin-left: 10%; font-size:13px">注册即代表您已阅读并同意《使用协议》</span>
    </div>
    </div>
  </div>
</template>
 
<script>
 
import axios from 'axios'
export default {
    data () {
         var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'));
        } else {
          if (this.form.pswok !== '') {
            this.$refs.form.validateField('pswok');
          }
          callback();
        }
      };
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.form.psw) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
        return {
            isforbidden:false,
            form:{
            name: '',
            verify:'',
            psw:'',
            pswok:'',
            email:""
            },
            rules: {
                psw: [
            { validator: validatePass, trigger: 'blur' }
          ],
                pswok: [
            { validator: validatePass2, trigger: 'blur' }
          ],
        }
        };
    },
    
    methods:{
        reg(form){
          this.$refs[form].validate((valid) => {
          if (valid) {
            axios({
            url:"http://127.0.0.1:5000/logmessage",
            params:{
                usename : this.form.name,
                password : this.form.psw,
                email:this.form.email,
                verify:this.form.verify
            },
            method:"GET"
           }).then((res)=>{
            if(res.data==true){
                this.$router.push("/login")
            this.$alert('恭喜,注册成功了', '提示', {
          confirmButtonText: '确定',
             });
             }else{
                this.$alert(res.data, '提示', {
                confirmButtonText: '确定',
             });
             }
            // console.log(res.data);
           },(err)=>{
            this.$alert('注册失败，请重新注册', '提示', {
          confirmButtonText: '确定',
             });
           })
          } else {
            this.$alert('请输入完整内容', '提示', {
          confirmButtonText: '确定',
          callback: action => {
            this.$message({
              type: 'error',
              message: `错误: ${ "请输入完整信息" }`
            });
          }
        });
            return false;
          }
        });
            // console.log(this.form.name);
        },
        //发送验证码
        verificationcode(e){
          if(this.form.email==''){
            this.$message.error('没有输入邮箱');
          }else{
            axios({
              url:"http://127.0.0.1:5000/emails",
              method:"POST",
              data:{
                email : this.form.email,
              }
            }).then((res)=>{
              console.log(res.data);
            },(err)=>{
              console.log("请求错误");
            })
            this.isforbidden=true;
            let k = 60;
            let time = setInterval(()=>{
              if(k==0){
                e.target.innerHTML="获取验证码";
                this.isforbidden = false;
                clearInterval(time);
              }else{
                e.target.innerHTML="还剩"+k+"秒";
                k--;
              }
            },1000)
          }
        }
    }
}
</script>

<style scoped>
.box{
    height: 100%;
    background-image: url(../../assets/images/timg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.register{
    width: 35%;
    height: 80%;
    background-color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    border-radius: 5px;
}
footer{
    font-family: '宋体';
    font-size: 24px;
    text-align: center;
    padding-top: 50px;
}
.from{
    width: 75%;
    margin: 0 auto;
    margin-right: 15%;
    margin-top: 8%;
}
.inputtow{
    width: 65%;
}
 
.buttonVerify{
    position: absolute;
    left: 64%;
    top: 31%;
}
.buttonres{
    width: 70%;
    margin-left: 15%;
    margin-top: 5%;
}
.login{
    margin-left: 15%;
    margin-top: 5%;
    color: #ccc;
    font: size 13px;
}
</style>